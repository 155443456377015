import React from 'react'
// import ReactDOM from 'react-dom'
import { SocialIcon } from 'react-social-icons'
import MyLinks from './Mylinks.jsx';

function Footer() {
    return ( <>
    <section className='h-[100vh] flex flex-col justify-end max-xl:hidden'>
    <MyLinks/>
    <div className="container mx-auto">
        <div className="footer-text">RUPESH</div>
            <div className="flex justify-center font-medium max-sm:mx-4 max-sm:justify-between" style={{borderTop: "1px solid black", paddingTop:"20px"}}>
                <div className="sm:flex">
                    <li className="mx-[2vw]">About</li>
                    <li className="mx-[2vw]">Skills</li>
                    <li className="mx-[2vw]">Projects</li>
                </div>
                <div className="sm:flex">
                    <li className="mx-[2vw]">Blog</li>
                    <li className="mx-[2vw]">Resume</li>
                    <li className="mx-[2vw]">Contact Me</li>
                </div>
            </div>
        
        <div className="flex justify-between mx-8 mb-4 pt-4 items-center">
            <div>© 2024 Rupesh Raj, All rights reserved.</div>
            <div className="flex list-none">
                <li className="mx-4 "><SocialIcon url="https://www.linkedin.com/in/rupesh-raj1997/" /></li>
                <li className="mx-4 "><SocialIcon url="https://github.com/rupesh-raj1997" /></li>
                <li className="mx-4 "><SocialIcon url="mailto:rupeshraj053@gmail.com" /></li>
                {/* <li className="mx-4 "><SocialIcon url="https://linkedin.com/in/couetilc" /></li> */}
            </div>
        </div>
    </div>
    </section>
    </>
     );
}

export default Footer;