function SmallScreen() {
    return ( 
        <>
        <div className="h-screen xl:hidden flex flex-col items-center justify-center">
            <span className="text-xl">Please Use a Wider Screen</span>
            <span className="justify-center flex items-center text-center">Mobile View<br/>Coming Soon</span>
        </div>
        </>
     );
}

export default SmallScreen;