import { useState } from 'react';
import { SocialIcon } from 'react-social-icons'
import logo from '../images/www.rupeshraj.png'




function Navbar() {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    const handleButtonClick = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    return ( 
        <>
        <header id="parent"
        className={`${
          isNavbarOpen
            ? 'mx-auto h-[15vh] h-screen fixed bg-[white] z-[100] w-full max-xl:hidden'
            : 'mx-auto h-[15vh] bg-[darkgray] max-xl:hidden'
        }`}>
            <nav className="justify-between flex mx-auto container leading-0">
                <div className="flex flex-col">
                    <span className="text-5xl">Rupesh</span>
                    <span className="text-5xl flex justify-content">
                        <span className="relative inline-block w-32  mr-4 h-4 bg-gray-200 dark:bg-dark-gray" style={{width: "4.5rem", marginTop:"26px"}}>
                            <span className="absolute bottom-full w-16 h-4 bg-[silver]" style={{width: "4rem"}}>
                            </span>
                        </span>
                        Raj
                    </span>
                    <span className="hidden text-lg">SOFTWARE ENGINEER</span>
                </div>

                <button onClick={handleButtonClick} className="text-5xl flex items-center group relative">
                    <span className="group-hover:-ml-[3rem]">M</span>
                    <span className="group-hover:-mb-[1em]">
                        <span className="flex -mb-[1em]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="visible group-hover:hidden w-12 h-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
                            </svg>
                        </span>
                    <span className="invisible group-hover:visible">E</span>
                    <span className="invisible group-hover:visible">N</span>
                    <span className="invisible group-hover:visible">U</span>
                    </span>
                </button>

            </nav>

                <section className={`${
          isNavbarOpen
            ? 'flex h-[80%] block'
            : 'hidden'
        }`}>
                    <div className='w-[70%] -l-[5vw] -mt-[20vw]'>
                    <div className="flex flex-col list-none" style={{rotate:'-90deg'}}>
                        <div className='/*mx-[5em]*/ flex flex-col'>
                            <li className='nav-text'>Links</li>
                            <div className='nav-partitioner my-[5em] '/>
                        </div>

                        <div className='/*mx-[5em]*/ flex flex-col'>
                            <li className='nav-text'>Resume</li>
                            <div className='nav-partitioner my-[5em]'/>
                        </div>

                        <div className='/*mx-[5em]*/ flex flex-col'>
                            <li className='nav-text'>Connect</li>
                            <div className='nav-partitioner my-[5em]'/>
                        </div>
                    </div>
                    </div>

                    <div className="flex flex-col w-[30%] justify-end">
                        <div className='mb-[8em]'>
                        <span className='text-3xl'>+91-80021-00967</span><br/>
                        <span className='text-3xl'>rupeshr053@gmail.com</span>

                        <div className='flex list-none mt-8'>
                        <li className="mx-2"><SocialIcon url="https://www.linkedin.com/in/rupesh-raj1997/" bgColor="gray" fgColor="black"/></li>
                        <li className="mx-2"><SocialIcon url="https://github.com/rupesh-raj1997" bgColor="gray" fgColor="black"/></li>
                        <li className="mx-2"><SocialIcon url="mailto:rupeshraj053@gmail.com" bgColor="gray" fgColor="black"/></li>
                        {/* <li className="mx-2"><SocialIcon url="https://github.com/in/couetilc" bgColor="gray" fgColor="black"/></li> */}
                        </div>
                        </div>

                        <div><img className='-ml-[6em]' src={logo} alt='aprvraj.in logo'/></div>
                    </div>
                </section>
        </header>
        </>
     );
}

export default Navbar;