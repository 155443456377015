import imgLink from '../images/links.svg'
import github from '../images/icons/github.png'
import twitter from '../images/icons/twitter.png'
import linkedin from '../images/icons/linkedin.png'
import email from '../images/icons/email.png'
 

function MyLinks() {
    return ( 
    <>
    <div className="container mx-auto ">

        <div className='flex mx-[12em] justify-between'>
            <h1 className="mt-[2em] text-left sm:text-7xl text-5xl font-black uppercase mb-4">
                <span className="relative inline-block w-32  mr-4 h-4 bg-gray-200 dark:bg-dark-gray" style={{width: "8rem"}}>
                    <span className="absolute bottom-full w-16 h-4 bg-[palegreen]" style={{width: "4rem"}}>
                    </span>
                </span>
                <span className="inline-block" style={{opacity: "1", transform: "none"}}>Links</span>
            </h1>
        {/* <div className="flex justify-center w-[40vw]">
            <img src={imgLink} className='w-[450px] mt-4' alt='connect on my socials'/>
        </div> */}
        </div>
        <div className="flex justify-between mx-[12em] h-[auto]">
            <a href='mailto:rupeshraj053@gmail.com' className="flex justify-end items-center linkprops border border-solid border-black w-[250px] h-[50px]">
                <img src={email} className='w-[30px] h-[30px] mr-[1em]' alt='icon' />
                <li>Mail</li>
            </a>

            <a href='https://www.linkedin.com/in/rupesh-raj1997/' className="flex justify-end items-center linkprops border border-solid border-black w-[250px] h-[50px]">
                <img src={linkedin} className='w-[30px] h-[30px] mr-[1em]' alt='icon' />
                <li>LinkedIn</li>
            </a>

            <a href='https://github.com/rupesh-raj1997' className="flex justify-end items-center linkprops border border-solid border-black w-[250px] h-[50px]">
                <img src={github} className='w-[30px] h-[30px] mr-[1em]' alt='icon' />
                <li>Github</li>
            </a>

            {/* <div className="flex bookprops border border-solid border-black w-[250px] h-[50px]">
                <img src={twitter} className='w-[30px] h-[30px] mr-[0.3em]' alt='icon' />
                <div>| Twitter</div>
            </div> */}

            {/* <div className="flex bookprops border border-solid border-black w-[250px] h-[50px]">
                <img src={github} className='w-[30px] h-[30px] mr-[0.3em]' alt='icon' />
                <div>| Stackoverflow</div>
            </div> */}

            {/* <div className="flex bookprops border border-solid border-black w-[250px] h-[50px]">
                <img src={github} className='w-[30px] h-[30px] mr-[0.3em]' alt='icon' />
                <div>| Facebook</div>
            </div> */}
        </div>

    </div>
    </>
     );
}

export default MyLinks;