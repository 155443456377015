import React from 'react';

const htmlString = `
<div class="card">
  <div class="titlebar">
    <span class="buttons">
    <button class="minimize buttoncenter">
	<svg x="0px" y="0px" viewBox="0 0 10.2 1"><rect x="0" y="50%" width="10.2" height="1">
      </rect>
      </svg>
	</button>
    
    <button class="maximize buttoncenter">
      	<svg viewBox="0 0 10 10"><path d="M0,0v10h10V0H0z M9,9H1V1h8V9z"></path>		</svg>
	</button>
    
    <button class="close buttoncenter">
		<svg viewBox="0 0 10 10"><polygon points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"></polygon></svg>
		</button>
    </span>
  </div>
  
  <div class="cppcode">
    
    <pre id="pre"><code><span class="s1">import { <span class="s4">FC</span> } from </span> <span class="s2">"react";</span>
type<span class="s3"> WelcomeProps </span>= <span class="sc">{</span>
  <span class="s3">uses:</span>
    <span class="s3">|</span> <span class="s4">"explore new tech"</span>
    <span class="s3">|</span> <span class="s4">"display my skills"</span>
    <span class="s3">|</span> <span class="s4">"find freelancing opportunities"</span>
    <span class="s3">|</span> <span class="s4">"find a fulltime job"<span class="sc">;</span></span>
<span class="sc">};</span>
      
<span class="s4">export const</span> <span class="s5">Welcome:</span> <span class="s1">FC&lt;<span class="s5">WelcomeProps</span>> = ({ <span class="s5">uses</span> }) => {

    <span class="s3">return (</span>
      <>
      &lt;h1><span class="s5">This is my little slice of the internet.</span>&lt;/h1>
        &lt;p>
           <span class="s5">I use this site to</span>&lt;em>{<span class="s5">uses</span>}&lt;/em>.
        &lt;/p>
      &lt;/>
   <span class="sc">);</span>
<span class="sc">};</span>

<span class="sc">export default <span class="s5">Welcome;</span></span>

    </code></pre>
  </div>
</div>

<style>
::-webkit-scrollbar {
  width: 0px;
}

.buttons{
  display:flex;
  justify-content:end;
}
.buttoncenter{
  display:flex;
  justify-content:center;
  align-items:center;
}

.card {
    border-radius:15px;
    width: 420px;
    height: fit-content;
    background: #15001f;
    border: 1px solid #c042ff;
    font-size: 14px;
    font-family: monospace;
    overflow: auto;
  }
  
  .titlebar {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 21px;
    font-weight: 450;
    background-color: #2e0043;
    width: 100%;
    text-align: right;
  }
  
  .card button {
    width: 40px;
    height: 35px;
    margin-left: -5px;
    border: 0;
    outline: 0;
    background: transparent;
    transition: 0.2s;
  }
  
  button svg path, 
  button svg rect, 
  button svg polygon {
    fill: #ffffff;
  }
  
  button svg {
    width: 10px;
    height: 10px;
  }
  
  .close:hover {
    background-color: #e81123;
  }
  
  .maximize:hover {
    background-color: #c042ff2e;
  }
  
  .minimize:hover {
    background-color: #c042ff2e;
  }
  
  #pre {
    overflow: auto;
    width: 90%;
    padding: 10px;
    height: auto;
    color: #bafff8;
  }
  
  .curlies {
    color: #ff0000;
  }
  
  .sc {
    color: #e600ff;
  }
  
  .rounds {
    color: #ffffff;
  }
  
  .operator {
    color: #ffff00;
  }
  
  .s1 {
    color: #22ff00;
  }
  
  .s2 {
    color: #4281ff;
  }
  
  .s3 {
    color: #ff4284;
  }
  
  .s4 {
    color: #ffae00;
  }
  
  .s5 {
    color: #ffffff;
  }
  
  .s6 {
    color: #ffff00;
  }
</style>
`

function HeroIntro() {
    return ( 
        <>
        <section className="bg-mesh1 h-screen max-xl:hidden">
            <div className="container relative mx-auto text-white">
                <div className="relative mx-auto flex max-w-6xl grid-cols-3 flex-col gap-8 gap-y-16 px-4 py-16 md:py-32 md:px-8 lg:grid">
                  <div className="col-span-2 flex flex-col">
                      <span className="text-2xl font-medium">Welcome to my site.</span>
                      <span className="text-7xl font-extrabold text-justify">I'm Rupesh Raj, a <br/><span className="">Fullstack developer.</span></span>
                      <span className="text-xl text-justify mb-3 mt-3 font-normal text-gray-400">As a software engineer and alum of BITS Pilani, I bring forth 3.5 years of industry experience. My domain expertise lies in front-end development, where I have proficiently worked with multiple frameworks like React, Svelte, and Vue.js. I put forth my best work by crafting scalable UI components. I ensure my products are always fast, readable, and bug-free. My involvement with multiple startups has made me aspire to work in fast-paced environments. I am looking forward to finding my next challenging problem and making a difference.</span> 
                      <span className="text-xl text-justify mb-3 font-extrabold text-gray-400">🚀  &lt; Crafting Digital Dreams /&gt;</span>

                      <div className="flex gap-4">
                          <span className="bg-gray-800 px-10 py-2.5 text-sm font-medium">Let's Work</span>
                          <span className="bg-gray-800 px-10 py-2.5 text-sm font-medium">Resume</span>
                      </div>
                  </div>
                  
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                  
                    <div className='h-[200px] w-[320px] p-4 -mt-[8em] -ml-[16em] absolute tweet-card drop-shadow-2xl' style={{backgroundColor:"#1e293d", borderRadius:"12px"}}>
                      <div className='flex gap-4 items-center'>
                          <div className=''><img className='w-[40px] h-[40px]' style={{borderRadius:"50%"}} src='https://i.stack.imgur.com/l60Hf.png'/></div>
                          <div className='flex flex-col'>
                            <div>Rupesh Raj</div>
                            <div className='text-[#cbd5e1e6]'>@rupeshraj</div>
                          </div>
                          <div className='ml-[50px]' style={{backgroundColor:"#0284c7e6", padding:'5px 15px 5px 15px', borderRadius:"9999px"}}>Follow</div>
                      </div>
                      <div className='flex flex-col pr-2 text-[13px] tracking-tight mt-2 line-clamp-3 d:text-gray-300/90 text-[#cbd5e1e6]'>
                      Hey, I'm new to twitter and not really a social media person 🙃 I'm looking to connect with fellow developers and to learn about the latest in web dev 🤗
                      <div className='flex gap-2 mt-2'>
                        <div><span className='text-[white]'>218</span> Following</div>
                        <div><span className='text-[white]'>51</span> Followers</div>
                      </div>
                      </div>
                    </div>
                  </div>

                </div>

                {/* <div className="absolute hidden sm:top-24 sm:-right-5 sm:block lg:top-full lg:-left-64">
                    <div className="max-w-xs animate-float rounded-lg border-2 border-gray-400/30 bg-white/90 p-4 backdrop-blur-md will-change-transform spacing-2 d:bg-gray-800 lg:bg-white/80">
                    </div>
                </div> */}

            </div>

        </section>
        </>
     );
}

export default HeroIntro;